import React, { useState, useMemo } from 'react';
import { Box, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { IAppState, PanicStatus } from 'types';
import { white, darkPaper, bgColorLight } from 'constants/colors';
import { agentInPlaceRequest, deassignAgentRequest, assignAgentRequest } from 'actions/panic';

import AgentChip from './agent-chip';
import { createSelector } from 'reselect';
import { DEASSIGNABLE_PANICS } from 'constants/panic';

export const useStyles = makeStyles({
  menuStyles: {
    '& ul': {
      backgroundColor: white,
      color: darkPaper,
      padding: 0,
    },
    '& li': {
      cursor: 'pointer',
      fontSize: '14px',
      textTransform: 'uppercase',
      padding: '4px 8px',
      '&:hover': {
        backgroundColor: bgColorLight,
      },
    },
  },
  chip: {
    flexDirection: 'row-reverse',
    '& .MuiChip-avatar': {
      marginRight: '-2px',
    },
  },
  agentBox: {
    '& .MuiChip-root': {
      height: '28px',
    },
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    },
    '& .MuiChip-avatar': {
      marginLeft: '-2px',
      width: '28px',
      height: '28px',
      border: `2px solid ${white}`,
    },
  },
});

type Props = {
  assignedAgentId?: number;
  isViewOnlyAgent?: (id: number) => boolean;
  panicInPlace?: string | Date | null;
  isPanicStatusOngoing?: boolean;
  toggleModal: Function;
  panicId: number;
  panicStatus: PanicStatus;
  panicAcceptedAt: string;
};

const AgentSelect = ({
  isPanicStatusOngoing,
  assignedAgentId,
  isViewOnlyAgent = (id: number) => !id,
  panicInPlace,
  panicId,
  panicAcceptedAt,
  panicStatus,
}: Props) => {
  const [anchor, setAnchor] = useState(null);
  const [agentId, setAgentId] = useState(0);
  const dispatch = useDispatch();
  const { menuStyles } = useStyles();

  const availableIdsSelector = createSelector(
    ({ family }: IAppState) => family.availableIds,
    (availableIds) => assignedAgentId ? [...availableIds, assignedAgentId] : availableIds,
  );

  const availableIds = useSelector(availableIdsSelector);
  const { byId } = useSelector(({ family }: IAppState) => ({
    allIds: family.allIds,
    byId: family.byId,
  }));
  const { memoizedById } = useMemo(() => ({ memoizedById: byId }), [byId]);

  const isMainAgent = assignedAgentId === Number(agentId);
  const showUnassign = DEASSIGNABLE_PANICS.includes(panicStatus);

  const handleStateReset = () => {
    setAgentId(0);
    setAnchor(null);
  };

  const agentInPlaceFn = () => {
    if (panicId) {
      dispatch(agentInPlaceRequest(panicId));
    }
  };

  const handleAgentUnassign = () => {
    const params = {
      id: panicId,
      data: {
        agentId: assignedAgentId,
      }
    };
    dispatch(deassignAgentRequest({ params, cb: handleStateReset }));
  };

  const handleAgentAssign = () => {
    const params = {
      id: panicId,
      data: {
        agentId,
        providerId: memoizedById[agentId].userProviders[0].providerId,
        reassign: panicStatus === PanicStatus.deassigned,
      },
    };

    dispatch(assignAgentRequest({ params, cb: handleStateReset }));
  };


  const handleClick = (event: any, el: number) => {
    if (panicStatus !== PanicStatus.inplace) {
      setAgentId(el);

      setAnchor(event.currentTarget);
    }
  };

  return (
    <div>
      {/* TODO: consider adding a scroll container with a max height for a big list of agents */}
      <Box display="flex" flexWrap="wrap">
        {Object.keys(memoizedById).map((key: string) => {
          if (isNaN(Number(key))) return null;
          const id = Number(key);
          const data = memoizedById[id];
          if (data.userProviders[0].role === 'operator') return null;
          return (
            <AgentChip
              key={id}
              data={data}
              assignedAgentId={assignedAgentId}
              handleClick={handleClick}
              disableClick={!!assignedAgentId && id !== assignedAgentId}
              isViewOnlyAgent={isViewOnlyAgent}
              panicInPlace={panicInPlace}
              agentId={agentId}
              isBusy={!availableIds.includes(id)}
            />
          );
        })}
        <Menu
          id={`${agentId || 'menu'}`}
          anchorEl={anchor}
          // keepMounted
          open={Boolean(anchor)}
          onClose={handleStateReset}
          className={menuStyles}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
        >
          {isPanicStatusOngoing && panicAcceptedAt && isMainAgent && (
            <MenuItem onClick={agentInPlaceFn}>
              <Box>
                <FormattedMessage id="panicLog.inplace_at" defaultMessage="Agent in place" />
              </Box>
            </MenuItem>
          )}

          {isPanicStatusOngoing && isViewOnlyAgent(Number(agentId)) && isMainAgent && showUnassign && (
            <MenuItem
              onClick={handleAgentUnassign}
            >
              <Box>
                <FormattedMessage id="common.remove_from_panic" defaultMessage="Remove" />
              </Box>
            </MenuItem>
          )}

          {isPanicStatusOngoing && !assignedAgentId && (
            <MenuItem onClick={handleAgentAssign}>
              <Box mr={1}>
                <FormattedMessage id="common.assign" defaultMessage="Assign" />
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </div>
  );
};

AgentSelect.defaultProps = {
  isPanicStatusOngoing: false,
  panic: {},
};

export default AgentSelect;
