import React from 'react';
import { Box, Dialog, ListItem } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import useStyles from './styles';
import { useFile } from 'utils/get-file';
import { Loader } from 'components';
import { FormattedMessage } from 'react-intl';

type Props = {
  open: boolean;
  toggleModal: Function;
  reportVideoId?: string;
  fullWidth?: boolean;
  abortSignal?: AbortSignal;
};

const VideoModal = ({ open, toggleModal, reportVideoId, abortSignal }: Props) => {
  const reportVideo = useFile(
    reportVideoId
      ? `v1/storage/file/protected/${reportVideoId}`
      : '',
    [reportVideoId],
    abortSignal
  );

  const classes = useStyles();
  return (
    <Dialog
      fullWidth={false}
      maxWidth={false}
      open={open}
      onClose={() => {
        toggleModal(false);
      }}
    >
      <Loader
        isLoading={!reportVideo}
        inContainer
      />
      <video src={reportVideo} autoPlay loop className={classes.image} controls/>
      <ListItem component={'a'} button href={reportVideo} download={`agent_video_report_${reportVideoId}`}>
        <Box display="flex" gridGap="8px" justifyContent="space-between" width="100%">
          <FormattedMessage id="common.download_video" />
          <GetAppIcon />
        </Box>
      </ListItem>
    </Dialog>
  );
};

export default VideoModal;
