import React from 'react';
import {
  Box,
  Chip,
  Avatar,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { AgentSelect } from 'components/input';
import useStyles from '../styles';
import ClientCard from '../client-card';
import PanicTimeline from '../timeline';
import ClientComment from '../client-comment-card';
import AgentComment from '../aget-comment-card';
import { formatPhoneNumber } from 'utils/formatPhone';
import { useFile } from 'utils/get-file';
import { IAppState } from 'types';
import { Loader } from 'components';
import { RESOLVED_PANICS } from 'constants/panic';

type Props = {
  toggleModal: () => void;
  panicId: number;
}

const SideInfo = ({
  toggleModal,
  panicId,
}: Props) => {

  const { panic, agentListById, client, agent,  } = useSelector(({ panic, family }: IAppState) => ({
    agentListById: family.byId,
    panic: (panic.byId[panicId] || {}),
    client: (panic.byId[panicId] || {})?.user,
    agent: (panic.byId[panicId] || {})?.agent,
  }));
  const disableAgentSection = useSelector(({ panic }: IAppState) =>
    panic.pendingAssignment.indexOf(panic.selectedPanicId) !== -1,
  );
  const classes = useStyles();
  const isPanicStatusOngoing = !RESOLVED_PANICS.includes(panic?.status);

  const isImageEnabled = useSelector(({ settings }: IAppState) => settings.isImageEnabled);

  const agentPhoto = useFile(agent?.photo && isImageEnabled? `v1/storage/file/public/${agent.photo.uuid}` : '');

  const isViewOnlyAgent = (agentId: number) => {
    // @TODO: Discuss with the team what view only means
    const isViewOnly = agentListById[agentId];

    return false || isViewOnly;
  };

  return (
    <>
      <Box className={classes.cardStyles} width="100%" position="relative">
        <Loader
          isLoading={disableAgentSection}
          inContainer
          text={<FormattedMessage id="agent.pending_assign" defaultMessage="Assigning Agent" />}
        />
        <Typography variant="body2">
          {panic?.agentId ? (
            <FormattedMessage id="agent.assigned" defaultMessage="Assigned Agent" />
          ) : (
            isPanicStatusOngoing && (
              <FormattedMessage id="mapPage.selectAgentLabel" defaultMessage="Select Agent" />
            )
          )}
        </Typography>
        {Boolean(panic?.status) && (
          isPanicStatusOngoing ? (
            <AgentSelect
              assignedAgentId={panic?.agentId}
              panicId={panic?.id}
              //@ts-expect-error lol
              isViewOnlyAgent={isViewOnlyAgent}
              panicInPlace={panic?.inPlaceAt}
              panicAcceptedAt={panic?.acceptedAt}
              panicStatus={panic?.status}
              isPanicStatusOngoing={isPanicStatusOngoing}
              toggleModal={toggleModal}
            />
          ) : (
            agent?.fullName && (
              <Box margin="16px 16px 16px 0" clone>
                <Chip
                  label={agent.fullName}
                  avatar={<Avatar src={agentPhoto} />}
                  color="default"
                  variant="default"
                />
              </Box>
            )
          )
        )}
        {Boolean(panic?.agentId) && (
          <Box mt={1}>
            <Box>
              <Typography variant="body1">
                {`${agent.fullName}, `}
                {agent?.phone && (
                  <a href={`tel:${agent?.phone}`} className={classes.callTo}>
                      +
                    {formatPhoneNumber(agent?.phone)}
                  </a>
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                <FormattedMessage id="common.eta_time" />
                {`: ${panic.agentEstimatedArrival || '-'}`}
                  min
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <ClientCard clientData={client || {}} />
      <ClientComment comment={panic?.comment} />
      <PanicTimeline client={client} agent={agent} panic={panic} />
      <AgentComment panicData={panic} />
    </>
  );
};




export default SideInfo;
