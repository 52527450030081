import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import { withFormik, Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import { editAgentValidations } from 'utils/validation';
import { CustomTextField, CustomSelect, CustomDatePicker, FileUpload } from '../input';

import { CustomSwitch } from 'components';
import { IField } from 'types';

type Props = {
  open: boolean;
  toggleModal: () => void;
  fields: IField[];
  handleSubmit: (event: any) => void;
  resetForm: () => void;
  setFieldValue: (name: string, el?: any) => void;
  setFieldError: (name: string, error: string) => void;
  setFieldTouched: (name: string, touched: boolean, validate?: boolean) => void;
  values: any;
  touched: any;
  errors: any;
  isLoading: boolean;
  disabledSelect?: boolean;
  initialValues?: object;
  fileUrl?: string;
};

const FormModal = ({
  open,
  toggleModal,
  fields,
  handleSubmit,
  setFieldValue,
  values,
  resetForm,
  touched,
  errors,
  isLoading,
  disabledSelect,
  setFieldError,
  setFieldTouched,
  fileUrl,
}: Props) => {

  return (
    <Dialog
      open={open}
      onClose={() => {
        toggleModal();
        resetForm();
      }}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="common.edit" defaultMessage="Edit" />
        </DialogTitle>
        <DialogContent>
          {fields.map(({ name, translationId, type, options, props }) => {
            if (type === 'photo') {
              return (
                <FileUpload
                  key={name}
                  name={name}
                  setFieldValue={setFieldValue}
                  helperText={touched[name] ? errors[name] : ''}
                  value={values[name] || fileUrl}
                  setFieldError={setFieldError}
                  setFieldTouched={setFieldTouched}
                />
              );
            }
            if (type === 'toggle') {
              return (
                <FormControlLabel
                  style={{ display: 'flex', justifyContent: 'space-between', margin: 0 }}
                  id={name}
                  key={name}
                  control={
                    <Field
                      id={name}
                      name={name}
                      component={CustomSwitch}
                      checked={values[name]}
                      onChange={(e: any, value: boolean) => {
                        setFieldValue(name, value)
                      }}
                    />
                  }
                  label={<FormattedMessage id={translationId} defaultMessage={name} />}
                  labelPlacement="start"
                />
              );
            }
            if (type === 'select') {
              return (
                <Field
                  key={name}
                  id={name}
                  name={name}
                  label={<FormattedMessage id={translationId} defaultMessage={name} />}
                  component={CustomSelect}
                  options={options}
                  margin="dense"
                  disabledSelect={disabledSelect}
                />
              );
            }
            if (type === 'date') {
              return (
                <CustomDatePicker
                  key={name}
                  name={name}
                  label={<FormattedMessage id={translationId} defaultMessage={name} />}
                  setFieldValue={setFieldValue}
                  value={values[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] ? errors[name] : ''}
                  {...props}
                />
              );
            }
            if (type === 'phone') {
              return (
                <Field
                  key={name}
                  id={name}
                  name={name}
                  label={<FormattedMessage id={translationId} defaultMessage={name} />}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  type={type}
                  component={CustomTextField}
                  margin="dense"
                />
              );
            }
            return (
              <Field
                key={name}
                id={name}
                name={name}
                label={<FormattedMessage id={translationId} defaultMessage={name} />}
                type={type || 'text'}
                component={CustomTextField}
                InputLabelProps={type === 'date' || type === 'color' ? { shrink: true } : {}}
                margin="dense"
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              toggleModal();
              resetForm();
            }}
            color="secondary"
            variant="outlined"
          >
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </Button>
          <Button size="small" type="submit" variant="outlined" color="default">
            {isLoading ? (
              <CircularProgress size={21} color="inherit" />
            ) : (
              <FormattedMessage id="common.save" defaultMessage="Save" />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

FormModal.defaultProps = {
  disabledSelect: false,
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues }: any) => ({
    ...initialValues,
  }),
  validationSchema: editAgentValidations,
  handleSubmit: (values, { props }) => {
    props.onConfirm({
      params: {
        ...values,
        ...(values.phone && {
          phone: values.phone.replace(/\s/g, ''),
        }),
        // formik is setting an empty field to string even if type is number and there is no option to dynamically change it
        // see https://github.com/jaredpalmer/formik/issues/1869
        // this will ensure that the type sent will be number or null
        // note: 0 will also be sent as null wich is fine
        weight: values.weight || null,
        height: values.height || null,
      },
    });
  },
})(FormModal);
